<template>
  <vs-tabs
    id="profile-tabs"
    :key="isSmallerScreen"
    :position="isSmallerScreen ? 'top' : 'left'"
    class="tabs-shadow-none"
  >
    <!-- GENERAL -->
    <vs-tab
      icon-pack="feather"
      icon="icon-user"
      :label="!isSmallerScreen ? 'General' : ''"
    >
      <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
        <user-settings-general />
      </div>
    </vs-tab>
    <!-- CHANGE PASSWORD -->
    <vs-tab
      icon-pack="feather"
      icon="icon-lock"
      :label="!isSmallerScreen ? 'Change Password' : ''"
    >
      <div class="tab-change-pwd md:ml-4 md:mt-0 mt-4 ml-0">
        <user-settings-change-password />
      </div>
    </vs-tab>
  </vs-tabs>
</template>

<script>
import UserSettingsChangePassword from './UserSettingsChangePassword';
import UserSettingsGeneral from './UserSettingsGeneral';

export default {
  components: {
    UserSettingsChangePassword,
    UserSettingsGeneral,
  },
  computed: {
    isSmallerScreen() {
      return this.$store.state.windowWidth < 768;
    },
  },
};
</script>

<style lang="scss">
#profile-tabs {
  .vs-tabs--content {
    padding: 0;
  }
}
</style>

<template>
  <div>
    <vs-alert
      v-if="firstLogin"
      color="warning"
      title="Complete your profile"
      active="true"
      class="mb-5"
    >
      Hello {{ name }}! This is your first login to the system. Please complete
      your profile information.
    </vs-alert>

    <vx-card>
      <div class="flex flex-wrap items-center mb-base">
        <vs-avatar :src="activeUserInfo.photo" size="70px" class="mr-4 mb-4" />
        <div>
          <input
            ref="uploadImgInput"
            type="file"
            class="hidden"
            name="photo_file"
            accept="image/*"
            @change="updatePicture"
          />

          <img v-if="uploading" src="@/assets/images/pages/loader.gif" />

          <div v-else>
            <vs-button
              class="mr-4 sm:mb-0 mb-2"
              @click="$refs.uploadImgInput.click()"
            >
              Upload photo
            </vs-button>
            <vs-button
              v-if="!activeUserInfo.is_default_photo || dataPictureFile"
              type="border"
              color="danger"
              @click="removePicture"
            >
              Remove
            </vs-button>
            <p class="text-sm mt-2">Allowed JPG, GIF or PNG.</p>
          </div>
        </div>
      </div>

      <div class="mt-4">
        <vs-label text="Name*" />
        <vs-input
          v-model="name"
          v-validate="'required|alpha_spaces'"
          class="w-full"
          name="name"
        />
        <v-error :err="errors" field="name" />
      </div>

      <div class="mt-4">
        <vs-label text="Email*" />
        <vs-input
          v-model="email"
          v-validate="'required|email'"
          class="w-full"
          name="email"
        />
        <v-error :err="errors" field="email" />
      </div>

      <div class="mt-4">
        <vs-label text="Mobile" />
        <vs-input v-model="mobile" class="w-full" name="mobile" />
      </div>

      <div class="mt-4">
        <vs-label text="Country*" />
        <v-select
          v-model="countryLocal"
          v-validate="'required'"
          :clearable="false"
          :options="countryList"
          name="country"
        />
        <v-error :err="errors" field="country" />
      </div>

      <div class="flex flex-wrap items-center justify-end mt-10 mb-2">
        <vs-button class="ml-auto mt-2" :disabled="!validateForm" @click="save">
          Save Changes
        </vs-button>
      </div>
    </vx-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import vSelect from 'vue-select';

import { countryList } from '@/enums/CountryEnum';
import { getMessageFromError } from '@/helpers';
import { ProfileAction } from '@/store/actionTypes';

import vError from '@/components/VError';

export default {
  components: {
    vError,
    vSelect,
  },
  data() {
    return {
      name: this.$store.state.AppActiveUser.name,
      email: this.$store.state.AppActiveUser.email,
      mobile: this.$store.state.AppActiveUser.mobile,
      isFirstLogin: this.$store.state.AppActiveUser.is_first_login,
      country: this.$store.state.AppActiveUser.country,
      dataPictureUrl: null,
      dataPictureFile: null,
      uploading: false,
      countryList,
    };
  },
  computed: {
    selectedCountry() {
      return this.countryLocal.value;
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    countryLocal: {
      get() {
        return this.countryList.find((item) => item.value === this.country);
      },
      set(obj) {
        this.country = obj.value;
      },
    },
    firstLogin: {
      get() {
        return this.$store.state.AppActiveUser.is_first_login;
      },
      set(val) {
        this.isFirstLogin = val;
      },
    },
    validateForm() {
      return (
        !this.errors.any() &&
        this.name !== '' &&
        this.email !== '' &&
        Boolean(this.countryLocal)
      );
    },
  },
  methods: {
    ...mapActions('profile', [
      ProfileAction.updateProfile,
      ProfileAction.uploadPhoto,
      ProfileAction.removePhoto,
    ]),

    async save() {
      this.$vs.loading();

      try {
        const { message, userData } = await this.updateProfile({
          name: this.name,
          email: this.email,
          mobile: this.mobile,
          country: this.selectedCountry,
          is_first_login: false,
        });

        this.firstLogin = userData.is_first_login;

        this.notifySuccess('Profile updated', message);
      } catch (error) {
        this.notifyError(error, getMessageFromError(error));
      }

      this.$vs.loading.close();
    },
    async updatePicture(input) {
      if (input.target.files && input.target.files[0]) {
        this.$vs.loading();

        this.uploading = true;

        const picture = input.target.files[0];
        const reader = new FileReader();

        this.dataPictureFile = picture;
        reader.onload = (e) => {
          this.dataPictureUrl = e.target.result;
        };
        reader.readAsDataURL(picture);

        const photoForm = new FormData();
        photoForm.append('photo_file', picture);

        try {
          const { message } = await this.uploadPhoto(photoForm);

          this.dataPictureUrl = null;
          this.dataPictureFile = null;

          this.notifySuccess('Profile updated', message);
        } catch (error) {
          this.notifyError(error, getMessageFromError(error));
        }

        this.uploading = false;
        this.$vs.loading.close();
      }
    },
    async removePicture() {
      if (this.dataPictureFile) {
        this.dataPictureUrl = null;
        this.dataPictureFile = null;

        return;
      }

      try {
        const { message } = await this.removePhoto();

        this.dataPictureUrl = null;
        this.dataPictureFile = null;

        this.notifySuccess('Profile updated', message);
      } catch (error) {
        this.notifyError(error, getMessageFromError(error));
      }
    },
  },
};
</script>

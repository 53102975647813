<template>
  <vx-card>
    <div class="mt-4">
      <vs-label text="Old password" />
      <vs-input
        v-model="old_password"
        v-validate="'required|min:8'"
        class="w-full"
        name="old_password"
      />
    </div>

    <div class="mt-4">
      <vs-label text="New Password (min 8 characters)" />
      <vs-input
        v-model="password"
        v-validate="'required|min:8'"
        class="w-full"
        name="password"
      />
    </div>

    <div class="mt-4">
      <vs-label text="Confirm Password (min 8 characters)" />
      <vs-input
        v-model="password_confirmation"
        v-validate="'required|min:8'"
        class="w-full"
        name="password_confirmation"
      />
    </div>

    <div class="flex flex-wrap items-center justify-end mt-10 mb-2">
      <vs-button class="ml-auto mt-2" :disabled="!validateForm" @click="save">
        Change password
      </vs-button>
    </div>
  </vx-card>
</template>

<script>
import { mapActions } from 'vuex';

import { getMessageFromError } from '@/helpers';
import { ProfileAction } from '@/store/actionTypes';

export default {
  data: () => ({
    old_password: '',
    password: '',
    password_confirmation: '',
  }),
  computed: {
    validateForm() {
      return (
        !this.errors.any() &&
        this.email !== '' &&
        this.password !== '' &&
        this.password_confirmation !== ''
      );
    },
  },
  methods: {
    ...mapActions('profile', [ProfileAction.changePassword]),

    reset() {
      this.old_password = '';
      this.password = '';
      this.password_confirmation = '';
    },
    async save() {
      this.$vs.loading();

      try {
        const { message } = await this.changePassword({
          old_password: this.old_password,
          password: this.password,
          password_confirmation: this.password_confirmation,
        });

        this.notifySuccess('Password changed', message);

        this.reset();
      } catch (error) {
        this.notifyError(error, getMessageFromError(error));
      }

      this.$vs.loading.close();
    },
  },
};
</script>
